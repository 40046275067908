/**
 *
 * CSS transitions and transform mixins
 *
 */

  // Example usage 
  //@include      transition(transform cubic-bezier(.26,.13,.06,1), .5s);
  
 @mixin transition($transitions,$timing) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition,$timing), comma);
  }
  
  -webkit-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition,$timing) {
  // Default values
  $property: all;
  $duration: $timing;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

// Transition Delay
// Example usage .transition-delay(.3s)
@mixin transition-delay($speed: 0.3s) {
  -webkit-transition-delay: $speed;
  -moz-transition-delay: $speed;
  transition-delay: $speed;
}

// CSS Transform
@mixin transform($arguments...) {
  -webkit-transform: $arguments;
  -moz-transform: $arguments;
  -o-transform: $arguments;
  -ms-transform: $arguments;
  transform: $arguments;
}
