@import "../../../00_fundaments/config.module";

.button {
	display: inline-flex; align-items: center; justify-content: center; padding: 8px 16px;
	font-family: font('bold'); font-size: 16px; line-height: 1.2; font-weight: 400;
	background: color('primary-1'); color: color('neutrals-10'); border: 1px solid color('primary-1');
	text-decoration: none; appearance: none; outline: none; cursor: pointer; user-select: none;
	position: relative; overflow: hidden;


	&:active, &:focus { outline: none; }

	&:before{
		content:					"";
		position:					absolute;
		left:  						0;
		right:  					0;
		top:						0;
		bottom:  					0;
		background-color:  			color('neutrals-0');
		transform:					translateY(calc(100% + 1px));
		transition: 				transform .3s cubic-bezier(0.77, 0, 0.175, 1);
		will-change:				transform;
	}

	&:hover{
		&:not(.background-is-white){
			&:before{
				transform:					translateY(0);
			}
		}
	}





	/*
	 *
	 * Define sizes */
	&.size-s { font-size: 14px; padding: 8px 16px; }
	&.size-l { font-size: 16px; padding: 16px 32px; }





	/*
	 *
	 * Define color variations */
	&.color-black { background: color('neutrals-10'); border-color: color('neutrals-10');

		&:before{
			background-color: color('neutrals-9');
		}

	}





	/*
	 *
	 * Define style variations */
	&.slanted {
		transform: skewX(20deg);

		span{
			transform: skewX(-20deg);
		}
	}





	/*
	 *
	 * Fullwidth button */


	 &.fullwidth{
		 width: 100%;
	 }





	/*
	 *
	 * Transparent button */


	 &.transparent{
		background-color: transparent;
		color: color('primary-1');
		border: 1px transparent;
	}




	 /*
	  *
	  * Define spacings */
	  &.spacing-0 { margin-bottom: spacers('size-0') }
	  &.spacing-s { margin-bottom: spacers('size-s'); }
	  &.spacing-m { margin-bottom:  spacers('size-m'); }
	  &.spacing-l { margin-bottom:  spacers('size-l'); }
	  &.spacing-xl { margin-bottom:  spacers('size-xl'); }
	  &.spacing-xxl { margin-bottom:  spacers('size-xxl'); }
	  &.spacing-xxxl { margin-bottom: spacers('size-xxxl'); }





	 /*
	  *
	  * Loading state */

	&.is-loading{
		.caption{

			&:after{
				content: 					"";
				position:					absolute;
				right:						0px;
				top: 						50%;
				border: 					1px solid rgba(0,0,0,.2);
				border-radius: 				50%;
				border-top: 				1px solid color('neutrals-10');
				width: 						20px;
				height: 					20px;
				margin-top: 				-10px;


				-webkit-animation: 			loading .5s linear infinite;
				animation: 					loading .5s linear infinite;
			}
		}
	}




}


/*
 *
 * Media Queries */

@include media-l{
	.button{
		padding: .75vw;
	}
}






/*
 *
 * Animations */

@-webkit-keyframes loading {
	0% {	 -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}

@keyframes loading {
	0% {	 transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
