@import '../../../00_fundaments/config.module';

.o-section{

	min-height:  				100vh;
	padding:					70px 30px;
	position: 					relative;
	display:  					flex;
	width:  					100%;
	
		&:not(:first-child), &:not(nth-child(2)){
			min-height:				120vh;
		}

	&.o-section--last{
		min-height:				100vh;
	}



	&.o-section--small{
		min-height:				auto;
	}


	@include media-m{
		padding:				10%;
	}


	@include media-l{
		padding:				15vh 8vw;

		.o-section--half{
			width: 50%;
		}


		.o-section--semi{
			width: 65%;
		}


		.o-section--right{
			margin-left:  		60%;
		}
	}


	@include media-xl{
		padding:				15vh 8vw;

		.o-section--half{
			width: 				50%;
		}

		.o-section--semi{
			width: 70%;
		}
	}
}





.o-section--full{
	width:						100%;
}


.o-section--darken{
	background-color:			color('neutrals-10');
	position:					absolute;
	top:						0;
	left:						0;
	right:						0;
	bottom:						0;
	opacity:					0;
	pointer-events:				none;
}




.o-section--cms{
	h1,h2,h3{
		margin-bottom:			spacers('size-xxl');
	}
}