.c-reputation-offer{
    display: flex;
    flex-direction: row;
    line-height: 120%;
    font-size: .85rem;
    width: 100%;



    img{
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 3vh;
        max-width: 30px;
        margin-right: 20px;
    }


    &.c-reputation-offer--large{
        font-size: 1rem;
    }
   
    

    @include breakpoint(l){
        align-items: center;
        flex-direction: column;
        width: 8vw;
        padding: 2vw;


        img{
            max-width: none;
            margin-right: 0;
        }

       &.c-reputation-offer--large{
            //width: 10vw;
       }
    }

}


.c-reputation-offer---spacer{
    margin-bottom: 3vh !important;
}