.cookieSettings {
	display: none; align-items: center; justify-content: center; width: 100%; height: 100%;
	position: fixed; left: 0; top: 0; z-index: 2000;
	background: rgba(color('neutrals-10'),.5); color: color('neutrals-8');
	overflow: auto; -webkit-overflow-scrolling: touch;

	&.is-visible { display: flex; }

	.cookieSettings__inner { max-height: 100vh; padding: 5px; }

	.cookieSettings__box {
		display: block;
		width: 100%; max-width: 650px;
		background: color('neutrals-0'); border: 1px solid #eee;
		padding: 35px 30px; margin: 50px 0; border-radius: 15px;
		box-shadow: 0px 15px 15px 0px rgba(color('neutrals-10'), 0.1);
		position: relative;
	}

	.cookieSettings__actions { display: flex; align-items: center; justify-content: center; width: 100%; margin: 0; }

	.cookieSettings__action { width: 100%; margin: 0 0 0 20px;

		&:first-child { margin-left: 0; }

	}

	.cookieSettings__options { background: color('light-1'); margin: 25px -30px; }

	.cookieSettings__option { display: flex; font-size: 14px; padding: 20px 30px;

		&:nth-child(even) { background: darken(color('light-1'), 3); }

	}

	.cookieSettings__optionContent { width: 100%; padding: 0 20px 0 0; }

	.cookieSettings__toggle { flex-shrink: 0; flex-grow: 0; }

	.cookieSettings__save { display: flex; align-items: center; justify-content: space-between; }

	.cookieSettings__links { font-size: 12px; line-height: 1.2; color: color('neutrals-5');  text-align: left;

		a { margin: 0 0 0 8px; color: color('neutrals-5');

			&:first-child { margin-left: 0; }

		}

	}


}










/*
 *
 *
 *
 * Mediaqueries */

/* Layout 01 & Layout 02: 0 - 767px */
@media all and (max-width: $layout-2) {

	.cookieSettings {

		.cookieSettings__actions { flex-wrap: wrap; }

		.cookieSettings__action { width: 100%; margin: 5px 0 0 0;

			&:first-child { margin: 0; }

		}

		.cookieSettings__save { flex-wrap: wrap;

			.button { display: flex; width: 100%; }

		}

		.cookieSettings__links { width: 100%; text-align: center; margin: 0 0 20px 0; }

	}

}