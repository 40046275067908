@import "../../../00_fundaments/config.module";

.input {

    input[type=text], input[type=email], input[type=password], textarea {
        width: 100%; height: 52px; padding: 5px 10px;
        background: color('neutrals-0'); border: 1px solid color('neutrals-1');
        font-family: font('main'); font-size: 14px; line-height: 1.2;

        &:focus { outline: none; border-color: color('neutrals-4'); }

    }

    textarea { min-height: 110px; padding-top: 15px; padding-bottom: 15px; }

    ::placeholder {
        font-family: font('special'); color: color('neutrals-3');
    }

}