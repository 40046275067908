.c-scroll-indication{
    display: none;
}

@include breakpoint(l){


    .c-scroll-indication{

        transform-origin:           center top;
        position:                   fixed;
        right:                      30px;
        bottom:                     0;
        transform:                  rotate(-90deg) translate3D(calc( 50% + 35px),0,0);
        cursor:                     pointer;
        display:                    block;
        opacity:                    0;
        transition:                 opacity 500ms;
        z-index:                    598;
    }





    .c-scroll-indication__text{

        color:                      $color-black;
        font-size:                  .9rem;
        animation:                  bounce 2.5s infinite;
    }




    .c-scroll-indication__line{
        position:                   absolute;
        left:                       -35px;
        top:                        50%;
        width:                      24px;
        height:                     0;
        margin-top:                 -1px;

        animation:                  scroll-down-line 2.5s infinite 851ms;


        &:after{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            border-bottom: 2px solid $color-black;
            transition: transform .5s cubic-bezier(.25,.46,.45,.94),opacity 0s linear .5s;
            transform-origin: right center;
            transform: scaleX(.001);
            opacity: 0;
            transform: none;
            opacity: 1;
            transition: transform 1s cubic-bezier(.23,1,.32,1),opacity 0s linear;
        }
    }





    @keyframes scroll-down-line {
      0% {
        transform-origin: 0% 50%;
        transform: scale(1, 1); }
      20% {
        transform-origin: 0% 50%;
        transform: scale(0.001, 1); }
      20.01% {
        transform-origin: 100% 50%; }
      38% {
        transform-origin: 100% 50%;
        transform: scale(1, 1); } }

    @keyframes bounce {
      0%, 15%, 38%, 60%, 75% {
        transform: translateX(0); }
      30% {
        transform: translateX(10px); }
      45% {
        transform: translateX(5px); }
    }



}










/* States
   ========================================================================== */



@include breakpoint(l){

    .c-scroll-indication.is-visible{
        opacity:            1;
    }

    .c-scroll-indication--white{

        .c-scroll-indication__text{
            color:          $color-white;
        }


        .c-scroll-indication__line:after{
            border-color:   $color-white;
        }
    }

    .nav-is-hidden {

      .c-scroll-indication.is-visible{
          opacity:            0;
          pointer-events: none;
      }

    }

}