/*
 *
 *
 *
 * Colors */
 $colors: (
	primary-1:      #ffc600,

	neutrals-0:     #ffffff,
	neutrals-1:     #e5e5e5,
	neutrals-2:     #cccccc,
	neutrals-3:     #b2b2b2,
	neutrals-4:     #999999,
	neutrals-5:     #7f7f7f,
	neutrals-6:     #656565,
	neutrals-7:     #4c4c4c,
	neutrals-8:     #333333,
	neutrals-9:     #191919,
	neutrals-10:    #000000,

	light-1:        #f0f0f0,

	succes:        	#38BF4B,
	error:        	#ff0000
);

@function color($color-name) { @return map-get($colors, $color-name); }

//@each $name, $value in $colors {
//	.fc--#{$name} { color: $value; }gss
//	.bgc--#{$name} { background: $value; }
//}




/*
 *
 *
 *
 * Layouts */

$layout-1:      767px;
$layout-2:      1023px;
$layout-3:      1279px;
$layout-4:      1550px;



/*
 *
 *
 *
 * Spacing */

 $spacers: (
	'size-0': 		0,
	'size-s': 		8px,
	'size-m': 		16px,
	'size-l': 		32px,
	'size-xl': 		64px,
	'size-xxl': 	128px,
	'size-xxxl': 	256px,
 );
 
 @function spacers($spacer-name) { @return map-get($spacers, $spacer-name); }



/*
 *
 *
 *
 * Fonts */
 $fonts: (
	'black':    	'Averta Black',
	'bold':    		'Averta Bold',
	'semibold':    	'Averta Semibold',
	'regular':    	'Averta Regular',
	'light':    	'Averta Light'
	
	
);

@function font($font-name) { @return map-get($fonts, $font-name); }

@mixin textrender() {
	text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased;
}

@mixin ellipsis() {
	display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
