.switch {

	label { position: relative; display: inline-block; width: 50px; height: 24px;

		&.disabled { opacity: 0.4; pointer-events: none; }

	}

	input { opacity: 0; width: 0; height: 0;

		&:checked + .switch__slider { background-color: color('primary-1');

			&:before { transform: translateX(26px); }

		}

	}

	.switch__slider {
		position: absolute; top: 0; left: 0; right: 0; bottom: 0;
		background-color: color('neutrals-2');
		border-radius: 34px; transition: all 400ms; cursor: pointer;

		&:before {
			content: ""; display: block; height: 20px; width: 20px;
			background-color: color('neutrals-0');
			position: absolute; left: 2px; bottom: 2px;
			border-radius: 50%; transition: .4s;
		}

	}

}
