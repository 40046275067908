/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * Buttons can be applied to any HTML element that is used to trigger a user
 * action (e.g. following a call to action link, submitting a form).
 *
 * 1. Line differently sized buttons up a little nicer.
 */




.c-button{

	background-color: 				$color-yellow;
	color:							$color-black;
	display: 						inline-block;
	padding: 						.7rem;
	text-decoration: 				none;

	font-size:						1rem;
	position: 						relative;
	overflow:  						hidden;
	//margin-left:					2%;
	cursor:  						pointer;
	user-select:					none;





	@include breakpoint(l){
		padding:					.75vw;
	}



	&.c-button--inline-play{

		.icon{
			width: 						0;
			height: 					0;
			border-top: 				8px solid transparent;
			border-bottom: 				8px solid transparent;

			border-left: 				12px solid $color-black;
			display:  					inline-block;


			margin:						0 20px 0 10px;
			z-index:  					$z-foreground;
		}

	}




	&:before{
		content:					"";
		position:					absolute;
		left:  						0;
		top:						0;
		width: 						100%;
		height: 					101%;
		background-color:  			$color-white;
		transform:					translate(0, 100%);
		transition: 				transform .3s cubic-bezier(0.77, 0, 0.175, 1);
		will-change:				transform;
	}



	.c-button__caption{
		display:  					inline-block;
		transition:					all .2s cubic-bezier(0.77, 0, 0.175, 1);
		position: 					relative;
	}


	&:focus{
		outline:					none;
	}

}




.c-button--black{
	background-color:				$color-black;
	color:							$color-white;

	&:before{
		background-color:  			$color-dark;
	}
}




.c-button-play-anim{
	width:							60px;
	height:  						60px;
	opacity: 						0;

	@include breakpoint(l){
		width:  					4vw;
		height:  					4vw;
	}
}



.c-button--play{

	width: 							60px;
	height: 						60px;
	background-color:  				$color-yellow;
	position: 						relative;
	overflow:  						hidden;
	display:  						block;
	cursor:  						pointer;


	&:focus{
		outline:					none;
	}


	&:before{
		content:					"";
		position:					absolute;
		left:  						-1px;
		right:  					-1px;
		top:						-1px;
		bottom:  					-1px;
		background-color:  			$color-white;
		transform:					translateY(100%);
		transition: 				transform .3s cubic-bezier(0.77, 0, 0.175, 1);
		will-change:				transform;
	}


	span{
		width: 						0;
		height: 					0;
		border-top: 				8px solid transparent;
		border-bottom: 				8px solid transparent;

		border-left: 				12px solid $color-black;
		display:  					inline-block;

		position:  					absolute;
		top:						50%;
		left:						50%;
		margin-left:  				-4px;
		margin-top:  				-8px;
		z-index:  					$z-foreground;
	}


	@include breakpoint(m){
		width:  					6vw;
		height:  					6vw;
	}


	@include breakpoint(xl){
		width:  					4.5vw;
		height:  					4.5vw;
	}

}










/* States
   ========================================================================== */


.c-button.is-loading{

	.c-button__caption{
		padding-right: 					calc(.2rem + 30px);

		&:after{

			content: 					"";
			position:					absolute;
			right:						0px;
			top: 						50%;
			border: 					1px solid rgba(0,0,0,.2);
			border-radius: 				50%;
			border-top: 				1px solid $color-black;
			width: 						20px;
			height: 					20px;
			margin-top: 				-10px;


			-webkit-animation: 			preload .5s linear infinite;
			animation: 					preload .5s linear infinite;
		}

	}

	&:before{
			transform:					translateY(0);
		}


}






/* Hover effects
   ========================================================================== */


.has-no-touch{


	.c-button:hover, .c-button--play:hover{

		&:not(.background-is-white){
			&:before{
				transform:					translate(0, 0);
			}
		}
	}


}









/* Animations
   ========================================================================== */

@-webkit-keyframes preload {

  0% {	 -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }

}

@keyframes preload {

  0% {	 transform: rotate(0deg); }
  100% { transform: rotate(360deg); }

}
