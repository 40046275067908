/* ==========================================================================
   #SCROLL SCENE
   ========================================================================== *



/**
 *
 * Scene background elements
 *
 */




.c-scene-visual, .c-scene-video{

	position:				fixed;
	top:					0;
	left:					0;
	right:					0;
	bottom:					0;
	margin:					0;

	display: 				none;


	//display:				none;

	&:not(:first-child){
		opacity:  			0;
		// clip-path:			polygon(-36.4vh  0%, -36.4vh 0%, 0% 100%, 0% 100%);
		// -webkit-clip-path:	polygon(-36.4vh  0%, -36.4vh 0%, 0% 100%, 0% 100%);
	}

	&.is-active{
		display:			block;
	}




}


.c-scene-video{
	left:					-50px;
	right:					-50px;
}




.c-scene-visual__img{

	background-size:		cover;
	background-position:	50% 50%;
	position:				fixed;
	top:					0;
	left:					-50px;
	right:					-50px	;
	bottom:					0;
}





.c-scene-visual__darken{
	position: 				absolute;
	top:					0;
	left:					0;
	right:					0;
	bottom:					0;
	background-color:		$color-black;
	opacity:				0.1;


	&.is-dark{
		opacity: 0.25;
	}
}


// .js-scene{
// 	border-top:				1px solid red;
// 	border-bottom:				1px solid red;
// }




// .c-scene-visual-slash{
// 	position: absolute;
//     overflow: hidden;
//     //background-color: rgba(0,0,0,.5);
//     transform-origin: 50% 0;
// 	min-height: 101vh;
//     transform: skewX(20deg);
//     bottom: -1px;
//     top: -1px;
//     left: calc(-.364 * 100vh);
//     right: 0;
//     @include scrimGradient($color-black,'to right');
//    	opacity:	.2;

// }








.c-scene-slash{

	position: 				fixed;
	right:					calc(-0.364 * 100vh);
	top:					0;
	left: 					calc(-0.364 * 100vh);
	bottom:  				0;
	transform:				translateX(-100%) skewX(20deg);

	@include breakpoint(l){
		right:				40vw;

		&.c-scene-slash--full{
			right:			calc(-0.364 * 100vh);
		}
	}




}



.c-scene-slash--white{
	background-color:  		#f2f2f2;
}



.c-scene-slash--black{
	background-color:  		$color-black;
}











.c-inline-slash{
	position: 				fixed;
	top:					0;
	left:  					0;
	right:  				0;
	bottom:  				0;
}






.js-scene-tbwa, .js-scene-job-detail{
	padding-bottom:  		50vh !important;
}







/**
 *
 * Scene foreground elements
 *
 */
