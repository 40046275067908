@import "../../../00_fundaments/config.module";

.offcanvas { 

    position:							fixed;
	right:  							15px;
	top:								5px;
	background-color:					transparent;
	border:								none;
	padding:							0;
	z-index:  							900;
	cursor:  							pointer;
	width:								50px;
    height:  							50px;


    &:focus{
		outline:  						none;
	}

	&:active{
		transform:						scale(.8);
    }
    
    .icon{

		width:  		                40px;
		height:  		                30px;
		position: 		                absolute;
		left:  			                5px;
		top:			                10px;

		&:focus{
			outline:  					none;
		}




		span{

			display: 		block;
			position: 		absolute;
			height: 		4px;
			width: 			40px;
			background: 	color('neutrals-0');
			opacity: 		1;
			left: 			0;
			transform:		rotate(0deg);
			transition:		.4s cubic-bezier(0.77, 0, 0.175, 1);

		}

		span:nth-child(1) {
			top: 0px;
		}

		span:nth-child(2), span:nth-child(3){
			top: 13px;
		}

		span:nth-child(4) {
			top: 26px;
        }
        



        @include media-m{
            position:   fixed;
            right:      30px;
            top:	    20px;
        }
	}



	/*
	 *
	 * Define variations */
    .offcanvas--is-open & {

        span { background: transparent;

            &:before { transform: rotate(45deg); }
            &:after { transform: rotate(-45deg); }

        }

    }

}