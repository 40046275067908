
.c-footer{
	display:  				block;
	position: 				fixed;
	left:					15px;
	bottom:  				15px;
	font-size:				.6rem;	
	transition:				transform .5s;
	transform:				translateY(0px);
}



.nav-is-hidden .c-footer{
	transform:				translateY(80px);
}




@include breakpoint(l){


	.c-footer{
		left:					30px;
		bottom:  				20px;
		font-size:				.7rem;	
	}



}