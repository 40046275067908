/* ==========================================================================
   #CUSTOM UTIL CLASSES
   ========================================================================== */




/**
 *
 * TRANSFORM UTILS
 *
 */


.u-skew{
	transform: 						skewX(20deg);
	
	
}


.u-skew-back{
	transform: 						skewX(-20deg);

}



.u-no-skew{
	transform: skewX(0deg);
}







/**
 *
 * TEXT UTILS
 *
 */



.u-text-white{
	color: $color-white;
}


.u-text-black{
	color: $color-black;
}


.u-text-yellow{
	color: $color-yellow;
}



.u-sup{
	font-size: 			35%;
    vertical-align: 	super;
    top: 				0;
    text-decoration: 	none;
}


.u-block-text{
	max-width: 			800px;

	.u-block\@l{
		transform:		skewX(-20deg);
	}
}


.u-block-text--xl{
	max-width: 			1024px;
}



.u-icon{
	width: 40%;
	max-width: 220px;
	height: auto;
	display: block;

	@include breakpoint(l){
		width:		25%;
	}
}


.u-social{
	width: 					40px;
	display:  				inline-block;
	margin-right:  			10px;


	@include breakpoint(xl){
		width:				2.5vw;
	}
}







.u-font-black{
	font-family:	"Averta Black";
	font-weight:  	400;
}

.u-font-bold{
	font-family:	"Averta Bold";
	font-weight:  	400;
}

.u-font-semibold{
	font-family:	"Averta Semibold";
	font-weight:  	400;
}

.u-font-regular{
	font-family:	"Averta Regular";
	font-weight:  	400;
}

.u-font-light{
	font-family:	"Averta Light";
	font-weight:  	400;
}





/**
 *
 * BGCOLOR UTILS
 *
 */


.u-background-white{
	background-color: $color-white;
}


.u-background-black{
	background-color: $color-black;
}


.u-background-yellow{
	background-color: $color-yellow;
}




.is-hidden{
	display: 			none;
}








/**
 *
 * Other utils
 *
 */


.u-rounded{
	border-radius:			100%;
}




/**
 *
 * RESPONSIVE BREAKPOINTS
 *
 */





@include breakpoint(m){


	/**
	 *
	 * TRANSFORM UTILS
	 *
	 */



	.u-skew\@m{
		transform: 						skewX(20deg);

	}


	.u-skew-back\@m{
		transform: 						skewX(-20deg);

	}

	.u-no-skew\@m{
		transform: 						skewX(0deg);

	}


}




@include breakpoint(l){


	/**
	 *
	 * TRANSFORM UTILS
	 *
	 */



	.u-skew\@l{
		transform: 						skewX(20deg);

	}


	.u-skew-back\@l{
		transform: 						skewX(-20deg);

	}

	.u-no-skew\@l{
		transform: 						skewX(0deg);

	}

}
