/* ==========================================================================
   #LOGO COMPONENT 
   ========================================================================== */




 .c-logo{

 	width:  				29.33%;
 	margin:					2%;
 	user-select:			none;



 	@include breakpoint(m){
		width:  			21%;
 	}



 	@include breakpoint(l){
		width:  			11.66%;
 	}
 	

 	@include breakpoint(xl){
		width:  			8.5%;
 	}
 	

 	img{
		width: 100%;
		height: 60%;
		object-fit: contain;
 	}	
 }