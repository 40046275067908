/* ==========================================================================
   #TBWA LOGO on the top of the page
   ========================================================================== */



.c-tbwalogo{

	position:						fixed;
	left:  							15px;
	top:							15px;
	text-decoration:  				none;
	cursor:  						pointer;


	z-index:  						600;


	img{
		height:  					30px;
	}

	

	@include breakpoint(m){
		position: 					fixed;
		top: 						30px;
		left:						30px;
	}

	.c-tbwalogo--dark{
		display:  					none;
	}

}



/* Style variants
   ========================================================================== */

.c-tbwalogo.is-inversed{

	.c-tbwalogo--white{
		display:  						none;
	}

	.c-tbwalogo--dark{
		display:  						block;
	}

}
