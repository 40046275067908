/* ==========================================================================
   #CASE DETAIL COMPONENT
   ========================================================================== */



.c-detail{


	position:		relative;
	min-height:  	100vh;
	background-color: 	$color-white;



	@include breakpoint(l){

	}
}



.c-job-detail{
	z-index: 10;
}


.c-case-detail__visual{

	//overflow:					hidden;
	height:  					50vh;
	position: 					relative;

	&:after{
		content:				"";
		position: 				absolute;
		top:					0;
		left:					0;
		right:					0;
		bottom:					0;
		background:				rgba(0,0,0,.1);
	}


	@include breakpoint(l){

		height:  				auto;

		position:  				fixed;
		top: 					0;
		//left:					0;
		min-height:  			100vh;
		right:					calc(40% - (0.364 * 50vh));
		left:					calc( 0% - (0.364 * 50vh));
	}


}









.c-case-detail__visual__img{
	position: 					absolute;
	top:						0;
	left:  						0;
	right:  					0;
	bottom:						0;

	background-size: 			cover;
	background-position: 		50% 50%;

}


.c-case-detail__visual--mobile{
	height:  					50vh;
	background-size:  			cover;
	background-position:  		50% 50%;
	//overflow:  					hidden;
	position:  					relative;




	.c-button--play{
		position: 				absolute;
		top:					50%;
		left:					50%;
		transform:				translateX(-50%) translateY(-50%) skewX(20deg) ;
	}

}







.c-case-detail__video__darken{
	position: 					fixed;
	top:						0;
	left:  						0;
	right:  					0;
	bottom:						0;
	background:   				rgba(0,0,0,.3);
	opacity:  					0;
}



.c-case-detail__slash{
	position: 				fixed;
	right:					calc(-0.364 * 100vh);
	top:					0;
	left: 					50%;
	bottom:  				0;



	&:after{
		content: 			"";
		display:  			block;
		transform:			skewX(20deg);
		background-color:  	$color-white;
		position: 			absolute;
		top:				0;
		left:  				0;
		right:  			0;
		bottom:  			0;
	}
}





.c-case-detail__darken{
	position: 				fixed;
	top:					0;
	left:  					0;
	right:  				0;
	bottom:  				0;
	background: 			rgba(0,0,0,.4);
	opacity:  				0;
}




.c-insight-wrapper{
	@include breakpoint(l){
		max-width:  	70vw;
	}
}





.c-case-detail__content{

	position: 					relative;
	min-height:  				50vh;
	padding:					10vh 10vw;



	@include breakpoint(l){

		background-color:  			transparent;

		display: 					flex;
		align-items: 				center;
		min-height:  				100vh;
		width:  					50vw;

		padding:					7%;
		padding-right:				calc(7% + 80px);


		h1{
			font-size:  			4.5vw;
			line-height:  			4.5vw;
		}



	}




}


.c-case-detail__media-controls{
	@include breakpoint(l){

		background-color:  			transparent;
		position: 					relative;
		display: 					flex;
		align-items: 				center;
		min-height:  				100vh;
		width:  					50vw;
	}
}





.c-case-detail__back{
	text-decoration:  				none;
	display:  						inline-flex;
	align-items:					center;
	transition:						.4s cubic-bezier(0.77, 0, 0.175, 1);
	margin-bottom:  				4vh;


	.arrow{
		width:  					35px;
	}
}



.has-no-touch .c-case-detail__back:hover{
	transform:						translateX(-10px);
}






.c-case-detail__media-controls__item{
	@include breakpoint(l){
		position: 					absolute;
		top:						0;
		left:						0;
		right:						0;
		bottom:						0;
	}



	.c-button-play-anim{
		position:  					absolute;
		left:  						50%;
		top:						50%;

		margin-left:  					-30px;
		margin-top:  					-30px;


		@include breakpoint(l){
			margin-left:				-2vw;
			margin-top:					-2vw;
		}

	}


	.c-button--play{
		position: 						absolute;
		top: 							50%;
		left:  							50%;

		margin-left:  					-30px;
		margin-top:  					-30px;


		@include breakpoint(l){
			margin-left:				-2vw;
			margin-top:					-2vw;
		}
	}

}











.c-case-detail__media-controls{

}









$control-width-mobile:			40px;






.c-case-detail__slash-insight{
	position: 				fixed;
	right: 					calc(-0.364 * 100vh);
	top:					0;
	left: 					calc(-0.364 * 100vh);
	bottom:  				0;
	transform:				translateX(-100%);


	@include breakpoint(m){
		right:					20%;
	}

	&:after{
		content: 			"";
		display:  			block;
		transform:			skewX(20deg);
		background-color:  	$color-black;
		position: 			absolute;
		top:				0;
		left:  				0;
		right:  			0;
		bottom:  			0;
	}
}





@include breakpoint(l){



	.c-case-detail__controls{
		position: 					absolute;
		right: 						30px;
		right: 						50%;
		z-index:  					$z-nav;

	}


	$control-width: 				40px;



}










/**
 *
 * States
 *
 */



.detail-is-visible{

}





.detail-is-animating{
	overflow:  					hidden;
}


