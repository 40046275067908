@import "./config.module";
@import "./mixins";


/*
 *
 *
 *
 * BOXING */
html, body { width: 100%; height: 100%; }
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }






/*
 *
 *
 *
 * GENERAL */

 body { background-color: color('neutrals-10'); font-family: font('regular'); font-size: 16px; line-height: 1.8; color: color('neutrals-0'); }

 @include media-m{
	 font-size: 18px; line-height: 32px;
 }

/*
 *
 *
 *
 * TYPOGRAPHY */
html, body { padding: 0; margin: 0; @include textrender(); }

p { margin: 0 0 15px 0;

	&:last-child { margin-bottom: 0; }

}

::selection { background: color('primary-1'); color: color('neutrals-0'); }