@import "../../../00_fundaments/config.module";

.paragraph { margin: 0 0 15px 0; padding: 0;


	/*
	 *
	 * Define Family */

	 &.font-light{ font-family: font('light'); }
	 &.font-regular{ font-family: font('regular'); }
	 &.font-bold{ font-family: font('bold'); }
	 &.font-semibold{ font-family: font('semibold'); }
	 &.font-black{ font-family: font('black'); }
 
 
 
 
 
	 /*
	  *
	  * Define sizes */
	 &.size-xs { font-size: 12px; line-height: 1.2; }
	 &.size-s { font-size: 14px; line-height: 1.2; }
	 &.size-m { font-size: 16px; line-height: 1.2; }
	 &.size-l { font-size: 20px; line-height: 1.2; }
	 &.size-xl { font-size: 25px; line-height: 1.2; }
	 &.size-xxl { font-size: 30px; line-height: 1.2; }
	 &.size-xxxl { font-size: 38px; line-height: 1.2; }
 
 
 
 
 
	 /*
	  *
	  * Define alignment */
	 &.align-left{ text-align: left; }
	 &.align-center{ text-align: center; }
	 &.align-right{ text-align: right; }
 
 
 
	 /*
	  *
	  * Define spacings */
	  &.spacing-0 { margin-bottom: spacers('size-0') }
	  &.spacing-s { margin-bottom: spacers('size-s'); }
	  &.spacing-m { margin-bottom:  spacers('size-m'); }
	  &.spacing-l { margin-bottom:  spacers('size-l'); }
	  &.spacing-xl { margin-bottom:  spacers('size-xl'); }
	  &.spacing-xxl { margin-bottom:  spacers('size-xxl'); }
	  &.spacing-xxxl { margin-bottom: spacers('size-xxxl'); }
  
 
 
 
 
 
	 /*
	  *
	  * Define weights */
	 &.weight-400 { font-weight: 400; }
	 &.weight-700 { font-weight: 700; }
	 &.weight-900 { font-weight: 900; }
 
 
 
 
 
	/*
	 *
	 * Define color */
	 &.color-white { color: color('neutrals-0'); }
	 &.color-white { color: color('neutrals-0'); }
	 &.color-primary { color: color('primary-1'); }


	/*
	*
	* Define variations */
	&:last-child { margin-bottom: 0; }

}