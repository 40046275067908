@import './config.module';


@mixin media-m {
	@media screen and (min-width: $layout-1) { @content; }
}

@mixin media-l {
	@media screen and (min-width: $layout-2) { @content; }
}

@mixin media-xl {
	@media screen and (min-width: $layout-3) { @content; }
}

@mixin media-xxl {
	@media screen and (min-width: $layout-4) { @content; }
}