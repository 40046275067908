@import '../../../00_fundaments/config.module';

.language {

    ul { display: flex; align-items: center; list-style: none; margin: 0; padding: 0; }

    li { margin: 0; line-height: 1;

        &:first-child { margin: 0; }

    }

    a { display: block; padding: 15px; background: color('light-1'); color: color('neutrals-8'); text-decoration: none;

        &.is-active { background: color('primary-1'); color: color('neutrals-0'); pointer-events: none; }

    }

}