.cookieMessage {
	display: none; align-items: center; justify-content: center; width: 100%; height: 100%;
	position: fixed; left: 0; top: 0; z-index: 2000;
	background: rgba(color('neutrals-10'),.5); color: color('neutrals-8');
	overflow: auto; -webkit-overflow-scrolling: touch;

	&.is-visible { display: flex; }

	.cookieMessage__inner { max-height: 100vh; padding: 5px; }

	.cookieMessage__box {
		display: block;
		width: 100%; max-width: 410px;
		background: color('neutrals-0'); border: 1px solid #eee;
		padding: 35px 30px; margin: 50px 0; border-radius: 15px;
		box-shadow: 0px 15px 15px 0px rgba(color('neutrals-10'), 0.1);
		position: relative;
	}

	.cookieMessage__content { width: 100%; font-size: 14px; line-height: 1.8; }

	.cookieMessage__actions { display: flex; flex-wrap: wrap; width: 100%; margin: 25px 0 0 0; }

	.cookieMessage__action { width: 100%; margin: 10px 0 0 0;

		&:first-child { margin-top: 0; }

	}

}